const ColumnContent = ({bottomTextTitle, bottomTextContent}) => {
  return (
    <div className="column-content">
      <h2 className="title">{bottomTextTitle}</h2>
      <p className="column-text">{bottomTextContent}</p>
    </div>
  );
};

export default ColumnContent;
