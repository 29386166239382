import React from "react";
import { useLocation } from "react-router-dom"; 
import Footer from "./footer";
import Header from "./Header";

const Layout = ({ children, footer, header }) => {
  const location = useLocation();
  const showFooter = location.pathname !== "/";
  
  return (
    <div>
      <Header header={header.content}/>
      {children}
      {showFooter && footer && footer.content && <Footer blok={footer.content} />} 
    </div>
  );
};

export default Layout;
