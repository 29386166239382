import YourInvestmentProjectContainer from "./yourInvestmentProjectContainer.js";

const YourInvestmentProject = ({ blok }) => {
  const { bgImage, bgImageMobile, datas, legendLeft, legendRight, source, text, title, chartPercent } = blok;

  return (
    <div className="your-investment-project__wrapper">
      <main className="your-investment-project your-investment-project">
        <div className="your-investment-project__top">
          <div
            className="bg-image"
            style={{
              backgroundImage: `url(${bgImage.filename})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="texts">
              <YourInvestmentProjectContainer
                title={title}
                text={text}
                legendLeft={legendLeft}
                legendRight={legendRight}
                percentage={chartPercent}
              />
            </div>
          </div>
          <div
            className="bg-image-mobile"
            style={{
              backgroundImage: `url(${bgImageMobile.filename})`,
              backgroundSize: "cover",
              backgroundPosition: "bottom",
              backgroundRepeat: "no-repeat",
            }}
          >
             <div className="texts">
              <YourInvestmentProjectContainer
                title={title}
                text={text}
                legendLeft={legendLeft}
                legendRight={legendRight}
                percentage={chartPercent}
              />
        </div>
        </div>
        </div>
      </main>
        <div className="your-investment-project__bottom">
      <YourInvestmentProjectContainer
        title={title}
        text={text}
        legendLeft={legendLeft}
        legendRight={legendRight}
        datas={datas}
        percentage={chartPercent}
      />
      </div>
    </div>
  );
};

export default YourInvestmentProject;
