import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import NavLink from "./Links.js";
import MenuMobile from "./menuMobile.js";

const Header = ({ header }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const {
    logo,
    logoUrl,
    menuMobile,
    Links,
    closeMenuMobile,
    phone,
    email,
    address,
    postalCode,
    country,
    socialMedias,
  } = header?.body[0] || {};

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };

  return (
    <div className="header-wrapper">
      <header className={`header ${!isHomePage ? "header-2" : ""}`}>
        <div className="logo">
          <a href={logoUrl}>
            <img src={logo && logo.filename} alt={logo?.name} />
          </a>
        </div>
        <nav className="nav-menu-container">
          <ul className="nav-menu">
            {Links &&
              Links.map(({ url, label, newWindow, isButton }, index) => (
                <NavLink
                  key={`header-menu-${index}`}
                  url={url}
                  label={label}
                  newWindow={newWindow}
                  isButton={isButton}
                  isHomePage={isHomePage}
                />
              ))}
          </ul>
        </nav>
        <img
          className="toggle-menu-mobile"
          src={menuMobile && menuMobile.filename}
          alt=""
          onClick={toggleMenu}
        />
        {isMenuOpen && (
          <MenuMobile
            logo={logo}
            logoUrl={logoUrl}
            closeMenuMobile={closeMenuMobile}
            Links={Links}
            phone={phone}
            email={email}
            address={address}
            postalCode={postalCode}
            country={country}
            socialMedias={socialMedias}
            toggleMenu={toggleMenu}
          />
        )}
      </header>
    </div>
  );
};

export default Header;
