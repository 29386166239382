import React from "react";

const YourInvestmentProjectContainer = ({ title, text, legendLeft, legendRight, percentage }) => {

  return (
    <div className="container">
      <h1 className="title">{title}</h1>
      <div className="content">
        <p className="text">{text}</p>
        {legendLeft && legendRight && percentage && (
          <>
        <div className="legend">
          <p>{legendLeft}</p>
          <p>{legendRight}</p>
        </div>
        <div className="datas">
          <div 
            className="data" 
            style={{
              background: percentage > 50 
                ? `linear-gradient(to right, black calc(${percentage}% - 100px), #F1D9AB calc(${percentage}% - 100px))`
                : `linear-gradient(to right, black ${percentage}%, #F1D9AB ${percentage}%)`,
            }}
          >
            <p style={{ color: percentage > 0 ? "white" : "black" }}>{percentage}%</p>
            <p className="months-left">15 months</p>
          </div>
        </div>
        </>
        )}
      </div>
    </div>
  );
};

export default YourInvestmentProjectContainer;
