export const formatUrl = (url, newWindow) => {
  if (!url) return '#';

  if (newWindow) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;
  }

  if (!url.startsWith('/')) {
    return `/${url}`;
  }

  return url;
};