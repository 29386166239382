import YourInvestmentProjectContainer from "./yourInvestmentProjectContainer.js";

import Map from "./projectDetails/map.js";
import ColumnContent from "./projectDetails/columnContent.js";
import Slider from "./projectDetails/slider.js";
import GeneralInfo from "./projectDetails/generalInfo.js";
const ProjectDetails = ({ blok }) => {
  const {
    bgImage,
    bgImageMobile,
    bedrooms,
    bottomTextContent,
    bottomTextTitle,
    details,
    gallerySubtitle,
    imageGallery,
    name,
    numberOfTowers,
    parkingSpaces,
    typeOfProperty,
    typology,
    mapEmbedCode,
    projectPercentage,
    monthsLeft,
  } = blok;

  return (
    <div className="your-investment-project__wrapper">
      <main className="your-investment-project">
        <div className="your-investment-project__top">
          <div
            className="bg-image"
            style={{
              backgroundImage: `url(${bgImage.filename})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div
            className="bg-image-mobile"
            style={{
              backgroundImage: `url(${bgImageMobile.filename})`,
              backgroundSize: "cover",
              backgroundPosition: "bottom",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>
      </main>
      <div className="project-details">
        <div className="real-estate-deal">
          <p>Real estate deal</p>
          <p>Property Delivery</p>
        </div>
        <div
          className="delivery-time"
          style={{
            background:
              projectPercentage > 50
                ? `linear-gradient(to right, black calc(${projectPercentage}% - 100px), #F1D9AB calc(${projectPercentage}% - 100px))`
                : `linear-gradient(to right, black ${projectPercentage}%, #F1D9AB ${projectPercentage}%)`,
          }}
        >
          <p style={{ color: projectPercentage > 0 ? "white" : "black" }}>
            {projectPercentage}%
          </p>
          <p>{monthsLeft}</p>
        </div>
        <div className="label">
          <p>Home</p>
          <p>|</p>
          <p>
            <span>Project name</span>
          </p>
        </div>
        <h2 className="title">{name}</h2>
        <GeneralInfo
          details={details}
          typeOfProperty={typeOfProperty}
          bedrooms={bedrooms}
          parkingSpaces={parkingSpaces}
          numberOfTowers={numberOfTowers}
          typology={typology}
        />
      </div>

      <Slider imageGallery={imageGallery} gallerySubtitle={gallerySubtitle} />

      <ColumnContent
        bottomTextContent={bottomTextContent}
        bottomTextTitle={bottomTextTitle}
      />

      <Map mapEmbedCode={mapEmbedCode} />
    </div>
  );
};

export default ProjectDetails;
