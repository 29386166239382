const GeneralInfo = ({details,typeOfProperty,bedrooms,parkingSpaces,numberOfTowers,typology}) => {

  return (
    <div className="general-information">
    <div className="general-information__left">{details}</div>
    <div className="general-information__right">
      <div className="general-information__right--top">
        <div className="details">
          <p>Tipo de Imóvel</p>
          <p>
            <span>{typeOfProperty}</span>
          </p>
        </div>
        <div className="details">
          <p>Quantidade de dorms.</p>
          <p>
            <span>{bedrooms}</span>
          </p>
        </div>
        <div className="details">
          <p>Vagas por unidade</p>
          <p>
            <span>{parkingSpaces}</span>
          </p>
        </div>
        <div className="details">
          <p>Número de torres</p>
          <p>
            <span>{numberOfTowers}</span>
          </p>
        </div>
      </div>
      <div className="general-information__right--bottom">
        <p>Typology</p>
        <ul>
          {typology.map((item, index) => (
            <li key={index}>{item.text}</li>
          ))}
        </ul>
      </div>
    </div>
  </div>
  )
}

export default GeneralInfo