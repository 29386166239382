import React, { useState, useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { useStoryblok, StoryblokComponent } from "@storyblok/react";
import Storyblok from "./storyblok"; // Certifique-se de que isso está correto
import Layout from "./components/Layout.js";
import './app.scss';
import { Helmet } from "react-helmet";

function AppContent() {
  const location = useLocation();
  const slug = location.pathname === "/" ? "home" : location.pathname.replace("/", "");
  
  const story = useStoryblok(slug, { version: "draft" });
  const dataFooterContent = useStoryblok('globalcontent/footercontent', { version: "draft" });
  const dataHeaderContent = useStoryblok('globalcontent/headercontent', { version: "draft" });

  const formatSlug = (slug) => {
    return slug
      .replace(/-/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  if (!story || !story.content) {
    return <div>Loading...</div>;
  }

  const formattedSlug = formatSlug(slug);

  return (
    <>
      <Helmet>
        <title>{`Hebbink - ${formattedSlug}`}</title>
        <meta name="description" content="Providing tailored solutions to help you find your ideal home."/>
        <meta name="keywords" content="palavras, chave, aqui" />
        <link rel="stylesheet" href="https://use.typekit.net/kku7mqy.css" />
      </Helmet>
      <Layout footer={dataFooterContent} header={dataHeaderContent}>
        <StoryblokComponent blok={story.content} />
      </Layout>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
