import React from "react";
import { formatUrl } from '../utils/functions.js';

const Footer = ({ blok }) => {
  const { socialMedias, link, phone, email, address, postalCode, rights } = blok?.body[0] || {};

  return (
    <footer>
      <div className="footer-desktop">
        <div className="footer-desktop__social-medias">
          {socialMedias &&
            socialMedias.map((item, index) => (
              <a
                key={`social-media-${index}`}
                href={formatUrl(item.link, true)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={item.image.filename} alt={`social-media-${index}`} />
              </a>
            ))}
        </div>
        <div className="footer-desktop__content">
          <div className="footer-desktop__content--left">
            <div>
              <h3>Contact</h3>
              <p>Phone: <a href={`tel:${phone}`}>{phone}</a></p>
              <p>E-mail: <a href={`mailto:${email}`}>{email}</a></p>
            </div>
            <div>
              <h3>Office</h3>
              <p>{ address }</p>
              <p>{ postalCode }</p>
            </div>
          </div>
          <div className="right">{ rights }</div>
        </div>
      </div>

      <div className="footer-mobile">
        <a href={formatUrl(link[0].url, false)}>{link[0].label}</a>
        <div className="footer-mobile__social-medias">
          {socialMedias &&
            socialMedias.map((item, index) => (
              <a
                key={`social-media-${index}`}
                href={formatUrl(item.link, true)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={item.imageMobileFooterOnly.filename} alt={`social-media-${index}`} />
              </a>
            ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
