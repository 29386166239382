import WhyLisbonContainer from "./whyLisbonContainer";

const WhyLisbon = ({ blok }) => {
  const { bgImage, datas, legendLeft, legendRight, source, text, title } = blok;

  return (
    <div className="why-lisbon__wrapper">
      <main className="why-lisbon">
        <div className="why-lisbon__top">
          <div
            className="bg-image"
            style={{
              backgroundImage: `url(${bgImage.filename})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="texts">
              <WhyLisbonContainer
                title={title}
                text={text}
                legendLeft={legendLeft}
                legendRight={legendRight}
                datas={datas}
                source={source}
              />
            </div>
          </div>
        </div>
      </main>
        <div className="why-lisbon__bottom">
      <WhyLisbonContainer
        title={title}
        text={text}
        legendLeft={legendLeft}
        legendRight={legendRight}
        datas={datas}
        source={source}
      />
      </div>
    </div>
  );
};

export default WhyLisbon;
