import React from "react";
import NavLink from "./Links.js";
import { formatUrl } from "../utils/functions.js";

const Hero = ({ blok }) => {
  const {
    bgDesktop,
    bgMedium,
    bgMobile,
    bgMobile2,
    links,
    SocialMedias,
  } = blok || {};

  return (
    <main className="home">
      <div
        className="bg-image"
        style={{
          backgroundImage: `url(${bgDesktop.filename})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div
        className="bg-image-medium"
        style={{
          backgroundImage: `url(${bgMedium.filename})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div
        className="bg-image-mobile"
        style={{
          backgroundImage: `url(${bgMobile.filename})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div
        className="bg-image-mobile2"
        style={{
          backgroundImage: `url(${bgMobile2.filename})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="slogan-wrapper">
        <div className="slogan">
          <h2 className="title-gradient-1">LISBON</h2>
          <h2 className="title-gradient-2">REAL ESTATE</h2>
          <h2 className="title-gradient-3">INVESTMENT</h2>
          <h2 className="title-gradient-4 disp-mobile">INVESTMENT</h2>
          <h2 className="title-gradient-4">REAL ESTATE</h2>
          <h2 className="title-gradient-5">LISBON</h2>
        </div>
      </div>
      
      <div className="hero">
        <div className="hero__top">
          <div className="bg-black"></div>
          <div className="bg-orange">
            <div className="content">
              <div className="content__left">
                {links &&
                  links.map(({ url, label, newWindow, isButton }, index) => (
                    <NavLink
                      key={`header-menu-${index}`}
                      url={url}
                      label={label}
                      newWindow={newWindow}
                      isButton={isButton}
                    />
                  ))}
              </div>
              <div className="content__right">
                <div className="social-medias">
                  {SocialMedias.map((item, index) => (
                    <div key={`social-media-${index}`}>
                      <a
                        href={formatUrl(item.link)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={item.image.filename} alt="" />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rights">
          <p>Copyright © Hebbink Invest 2024</p>
        </div>
      </div>
    </main>
  );
};

export default Hero;
