const Map = ({ mapEmbedCode }) => {
  return (
    <div className="map">
      <iframe
        src={mapEmbedCode}
        width="600"
        height="450"
        title="Map"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
