import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { formatUrl } from '../utils/functions.js';

const NavLink = ({ url, label, newWindow, isButton, isHomePage, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === url;

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <li className={`${isHomePage && isButton ? 'isButton' : ''} ${!isHomePage && isButton ? 'isButton-2' : ''}`}>
      <Link
        to={formatUrl(url, newWindow)}
        target={newWindow ? "_blank" : "_self"}
        rel={newWindow ? "noopener noreferrer" : undefined}
        className={isActive ? 'active' : ''}
        onClick={handleClick}
      >
        {label}
      </Link>
    </li>
  );
};

export default NavLink;
