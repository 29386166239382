import { storyblokInit, apiPlugin } from "@storyblok/react";
import Page from "./components/Page";
import Teaser from "./components/Teaser";
import Grid from "./components/Grid";
import Feature from "./components/Feature";
import Footer from "./components/footer";
import Header from './components/Header';
import MenuMobile from './components/menuMobile';
import NavLink from './components/Links';
import Hero from './components/Hero';
import WhyLisbon from './components/whyLisbon';
import WhyLisbon2 from './components/whyLisbon2';
import WhyLisbonContainer from './components/whyLisbonContainer';
import About from './components/About';
import About2 from './components/About2';
import YourInvestmentProject from './components/yourInvestmentProject';
import YourInvestmentProject2 from './components/yourInvestmentProject2';
import YourInvestmentProjectContainer from './components/yourInvestmentProjectContainer';
import Layout from './components/Layout';
import ProjectDetails from "./components/projectDetails";

const Storyblok = storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_KEY,
  use: [apiPlugin],
  components: {
    page: Page,
    teaser: Teaser,
    grid: Grid,
    feature: Feature,
    footer: Footer,
    header: Header,
    links: NavLink,
    menuMobile: MenuMobile,
    hero: Hero,
    whyLisbon: WhyLisbon,
    whyLisbon2: WhyLisbon2,
    whyLisbonContainer: WhyLisbonContainer,
    about: About,
    about2: About2,
    yourInvestmentProject: YourInvestmentProject,
    yourInvestmentProject2: YourInvestmentProject2,
    yourInvestmentProjectContainer: YourInvestmentProjectContainer,
    layout: Layout,
    projectDetails: ProjectDetails
  },
  apiOptions: {
    region: '',
  },
});

export default Storyblok;
