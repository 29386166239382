const WhyLisbon2 = ({ blok }) => {
  const { title, chartTitle, chartDatas, text } = blok;
  const colors = ['#F1D9AB', '#B5A58B', '#A1C5D3'];

  return (
    <div className="why-lisbon-2__wrapper">
      <h2>{title}</h2>
      <div className="why-lisbon-2">
        <div className="charts">
          <h3>{chartTitle}</h3>
          {chartDatas.map((chartData, index) => (
            <div
              className="data"
              key={chartData._uid}
              style={{
                background: `linear-gradient(90deg, ${colors[index % colors.length]} ${chartData.percent}%, #F3F2F0 ${chartData.percent}%)`,
              }}
            >
              <p>{chartData.name}</p>
              <p>{chartData.percent}%</p>
            </div>
          ))}
        </div>
        <div className="texts">
          {text.map((paragraphData) => (
            <p key={paragraphData._uid}>{paragraphData.paragraph}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyLisbon2;
