const About2 = ({ blok }) => {
  const { title, services } = blok;

  return (
    <div className="about-2">
      <h2>{title}</h2>
      <div className="services">
        {services.map((service, index) => (
          <div key={index} className="service">
            <div className="service-border"></div>
           <h3>{service.title}</h3>
           <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About2;
