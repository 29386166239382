import React from "react";

const WhyLisbonContainer = ({ title, text, legendLeft, legendRight, datas, source }) => {
  return (
    <div className="container">
      <h1 className="title">{title}</h1>
      <div className="content">
        <p className="text">{text}</p>
        <div className="legend">
          <p>{legendLeft}</p>
          <p>{legendRight}</p>
        </div>
        <div className="datas">
          {datas &&
            datas.map(({ name, price }, index) => (
              <div className="data" key={index}>
                <p>{name}</p>
                <p>{price}€</p>
              </div>
            ))}
        </div>
        <p className="source">{source}</p>
      </div>
    </div>
  );
};

export default WhyLisbonContainer;
