import React from 'react';
import { useNavigate } from 'react-router-dom';

const YourInvestmentProject2 = ({ blok }) => {
  const { title, projects, slug } = blok;
  const navigate = useNavigate();

  const handleRedirect = (slug) => {
    navigate(`/projects/${slug}`);
    window.scrollTo(0, 0);
  };

  console.log('slug', slug)
  console.log('blok', blok)

  return (
    <div className="your-investment-project-2__wrapper">
      <h2>{title}</h2>
      <div className="projects">
        {projects.map((project, index) => (
          <div
            key={index}
            className="project"
            onClick={() => handleRedirect(project.slug)} // Usa navigate ao invés de history.push
          >
            <img src={project.image.filename} alt={project.name} className="project-image" />
            <div className="project--description">
              <h3>{project.name}</h3>
              <p>By {project.architect}</p>
              <div className="area">
                <p>{project.area} - </p>
                <p>{project.size}</p>
              </div>
            </div>
            <div className="price">
              <p>Price per sqm</p>
              <p>{project.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YourInvestmentProject2;
